
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');


@font-face {
  font-family: 'Jeko ExtraBold';
  src: url('/public/fonts/FontsFree-Net-Jeko-Extra-Bold.ttf') format('truetype');
  font-weight: 800; /* ExtraBold is typically 800 */
  font-style: normal;
  font-display: swap; /* Ensures text is visible while the font loads */
}

.navbar {
  width: 100%;
  height: 100px;
  background: #000000;
  background-size: contain;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
  font-family: 'Jeko ExtraBold', sans-serif;
}

/* Navbar Left Section (Logo & Title) */
.navbar-left {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 30px;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
}

.span-logo {
  color: #61d678;
}

.navbar-logo {
  height: 90px;
  margin-left: 20px;
  text-decoration: none;
  cursor: pointer;
  font-size: 2rem;
  color: white;
  margin-top: 2px;
}

.navbar-logo-text {
  color: white;
  text-decoration: none;
  width: 30px;
}

/* Nav Menu */
.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
  margin-right: 80px;
  gap: 25px;
  font-family: 'Jeko ExtraBold', sans-serif;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 30px;
  transition: color 0.3s ease;
}

.nav-links:hover {
  color: #787efe;
  
}

/* Logo container */
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 0.3s ease; /* Smooth transition */
  cursor: pointer;
  text-decoration: none;
}

.pad-button {
  text-decoration: none;
  background: linear-gradient(90deg, #ff8c42, #ff3d8a);
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: #ffffff;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(255, 61, 138, 0.4);
  transition: all 0.3s ease-in-out;
}

/* Logo image */
.logo {
  width: 30px; /* Increased size to match font size */
  height: 30px;
  transition: filter 0.3s ease;
}

/* Hover effect for logos */

.logo-container:hover {
  color: #787efe; /* Change color on hover */
  transition: color 0.3s ease; /* Smooth color transition */
}
/* Mobile Menu Icon */
.menu-icon {
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: white;
}

/* Responsive Styles */
@media screen and (max-width: 800px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    justify-content: start;
    gap: 0px;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #000000;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    height: 50px;
    
    padding-left: 2rem;
  }

  .nav-links {
    text-align: left;
    padding: 1rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #275b9a3a;
    border-radius: 5px;
    text-decoration: underline;
  }

  .navbar-logo {
    margin-top: -6px;
    height: 70px;
    
  }

  .navbar-left {
    position: absolute;
    margin-left: -40px;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    margin-top: 15px;
    margin-right: 40px;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
    position: relative;
   
    margin-top: -18px;
  }
}

@media screen and (max-width: 500px) {
  .navbar {
    max-width: 100vw;
    width: 100vw;
  }
}