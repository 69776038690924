/* 🔹 General Section Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #000000;
}
.homepage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000000; /* Pure Black Background */
  padding: 3rem 8%;
  color: #ffffff;
  height: 100vh;
  max-width: 100%;
  flex-wrap: wrap; /* Ensures items wrap for smaller screens */
  gap: 2rem; /* Adds spacing between content blocks */
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
}

/* 🔹 Hero Content */
.hero-content {
  max-width: 750px;
  flex: 1;
  text-align: left;
  height: 43%;
 
}

.hero-heading {
  font-size: 3.4rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.2;
  margin-bottom: -15px;
}

.hero-heading .highlight {
  background: linear-gradient(90deg, #787efe, #a85ef7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-paragraph {
  font-size: 1.6rem;
  line-height: 1.3;
  margin: 1.5rem 0;
  color: #d4d4d4;
  
}

/* 🔹 Contract Address Section */
.contract-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #1a1a1a;
  padding: 0.6rem 1rem;
  border-radius: 8px;
  font-size: 1rem;
  color: #ffffff;
  max-width: fit-content;
  border: 1px solid #787efe;
  flex-wrap: wrap; /* Ensure elements wrap on smaller screens */
}

.contract-text {
  color: #a1a1a1;
  background-color: transparent;
}

.contract-address {
  font-weight: bold;
  color: #787efe;
  word-break: break-all; /* Prevents text overflow */
  background-color: transparent;
}

/* 🔹 Copy Button */
.copy-button {
  background: transparent;
  border: none;
  cursor: pointer;
  height: 16px;
  color: #ffffff;
  transition: transform 0.2s ease-in-out;
  background-color: transparent;
}

.copy-button:hover {
  transform: scale(1.2);
  color: #ff8c42;
}

/* Copied Message */
.copied-message {
  color: #ff8c42;
  font-size: 0.9rem;
  margin-left: 5px;
}

/* 🔹 Button Design */
.hero-button {
  background: #6c00ff; /* Use the vibrant purple background from the image */
  color: #ffffff; /* White text */
  border: 2px solid #000000; /* Black border */
  padding: 0.6rem 1.5rem; /* Adjust padding for the compact look */
  font-size: 1rem; /* Slightly smaller font */
  font-weight: 600;
  border-radius: 8px; /* Subtle rounded corners */
  cursor: pointer;
  box-shadow: 4px 4px 0px #000000; /* Offset black shadow for the layered look */
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.hero-button:hover {
  transform: translateY(-2px); /* Subtle lift on hover */
  box-shadow: 6px 6px 0px #000000; /* Enhance shadow depth */
  background: #7d00ff; /* Slightly brighter hover state */
}


/* 🔹 Hero Image */
.right-image {
  max-height: 800px;
  max-width: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex: 1;
  margin-right: -90px;
}

.r-img {
  width: 700px;
  max-width: 100%;
  animation: float 4s ease-in-out infinite;
}

/* 🔹 Floating Animation */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* 🔹 Responsive Design */

/* Medium Screens: Tablets */
@media (min-width: 1800px) {
  .homepage {
    padding: 0px;
    height: 100vh;
    max-width: 100%;
  }

  .hero-content {
    margin-top: 120px;
    margin-left: 0px;
  }

  .hero-heading {
    width: 90%;
    font-size: 5rem;
  }

  .hero-paragraph {
    font-size: 1.7rem;
  }

  .hero-button {
    font-size: 1.1rem;
    padding: 0.8rem 1.6rem;
  }

  .right-image {
    margin-top: 2rem;
  }

  .r-img {
    width: 80%;
  }
}


@media (max-width: 850px) {
  .largeBtn {
    margin-top: 20px;
  }
  .homepage {
    height: 100%;
    flex-direction: column-reverse; /* Stack elements vertically */
    text-align: center; /* Center-align text */
    align-items: center;
    padding: 20px;
    padding-top: 80px;
    gap: 0rem; /* Space between hero content and image */
  }

  .hero-content {
    margin-bottom: 20px;
    max-width: 100%; /* Expand content to full width */
    align-items: center;
    text-align: center;
  }

  .navbar {
    width: 100%;
  }

  .hero-heading {
    font-size: 2.8rem; /* Scale down heading size */
    padding-top: -12px;
  }

  .hero-paragraph {
    font-size: 1.4rem; /* Adjust paragraph size */
    padding: 1px 16px;
  }

  .contract-container {
    margin: auto;
    padding-bottom: 40px;
    align-items: center;
    flex-wrap: wrap; /* Wrap elements inside */
    justify-content: center; /* Center elements */
    padding: 0.5rem 0.8rem; /* Adjust padding */
    gap: 10px; /* Reduce gap between elements */
  }

  .contract-address {
    font-size: 0.9rem; /* Adjust contract font size */
    word-break: break-word; /* Prevent overflow */
  }

  .hero-button {
    font-size: 1rem; /* Adjust button size */
    padding: 0.7rem 1.4rem;
    margin-top: 1rem; /* Add spacing above the button */
    margin: 1rem auto auto auto;
  }

  .right-image {
    margin: auto;
  }

  .r-img {
    max-width: 55%; /* Increase the percentage for a larger size */
    width: auto; /* Ensure it scales naturally */
    margin: auto;
    padding-bottom: 0; /* Remove padding if not needed */
  }
}

@media (max-width: 500px) {
  .homepage {
    height: 100%;
    flex-direction: column-reverse; /* Stack elements vertically */
    text-align: center; /* Center-align text */
    align-items: center;
    padding: 20px;
    padding-top: 80px;
    gap: 0rem; /* Space between hero content and image */
  }

  .hero-content {
    margin-bottom: 20px;
    max-width: 100%; /* Expand content to full width */
    align-items: center;
    text-align: center;
  }

  .hero-heading {
    font-size: 2.3rem; /* Scale down heading size */
    padding-top: -12px;
  }

  .hero-paragraph {
    font-size: 1.1rem; /* Adjust paragraph size */
    padding: 1px 16px;
  }

  .contract-container {
    margin: auto;
    padding-bottom: 40px;
    align-items: center;
    flex-wrap: wrap; /* Wrap elements inside */
    justify-content: center; /* Center elements */
    padding: 0.5rem 0.8rem; /* Adjust padding */
    gap: 10px; /* Reduce gap between elements */
  }

  .contract-address {
    font-size: 0.9rem; /* Adjust contract font size */
    word-break: break-word; /* Prevent overflow */
  }

  .hero-button {
    font-size: 1rem; /* Adjust button size */
    padding: 0.7rem 1.4rem;
    margin-top: 1rem; /* Add spacing above the button */
    margin: 1rem auto auto auto;
  }

  .right-image {
    margin: auto;

  }

  .r-img {
    max-width: 85%; /* Increase the percentage for a larger size */
    width: auto; /* Ensure it scales naturally */
    padding-bottom: 0;
    margin: auto;
    padding-top: 20px;
    
  }
}
